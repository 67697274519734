import { Box } from "@mui/material";

const Page_404 = ({ contentLeftMargin }) => {
  return (
    <Box
      sx={{
        height: "80vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img
        src="/assets/404.png"
        alt="404 Page Not Found"
        style={{
          maxWidth: "100%",
          maxHeight: "100%",
          objectFit: "contain",
        }}
      />
    </Box>
  );
};

export default Page_404;
