import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159),
  createData("Ice cream sandwich", 237),
  createData("Eclair", 262),
  createData("Cupcake", 305),
  createData("Gingerbread", 356),
];

export default function TicketByTypeRepo({
  currentPeriodReport,
  currentParking,
  ticketByTypes,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  let [free_tickets_count, free_tickets_amount] = ticketByTypes.free_cars
    .split("|")
    .map(Number);

  let [between_2_and_3_count, between_2_and_3_amount] =
    ticketByTypes.count_between_2_and_3.split("|").map(Number);

  let [between_4_and_5_count, between_4_and_5_amount] =
    ticketByTypes.count_between_4_and_5.split("|").map(Number);

  let [between_6_and_60_count, between_6_and_60_amount] =
    ticketByTypes.count_between_6_and_60.split("|").map(Number);

  let [between_61_and_120_count, between_61_and_120_amount] =
    ticketByTypes.count_between_61_and_120.split("|").map(Number);

  let [between_121_and_180_count, between_121_and_180_amount] =
    ticketByTypes.count_between_121_and_180.split("|").map(Number);

  let [more_than_180_count, more_than_180_amount] = ticketByTypes.more_than_180
    .split("|")
    .map(Number);

  let total_count =
    free_tickets_count +
    between_2_and_3_count +
    between_4_and_5_count +
    between_6_and_60_count +
    between_61_and_120_count +
    between_121_and_180_count +
    more_than_180_count +
    currentPeriodReport.pt_count;
  let total_amount =
    free_tickets_amount +
    between_2_and_3_amount +
    between_4_and_5_amount +
    between_6_and_60_amount +
    between_61_and_120_amount +
    between_121_and_180_amount +
    more_than_180_amount +
    currentPeriodReport.pt_amount;
  return (
    <Box
      gridColumn={{
        xs: "span 2", // Full width on extra-small screens
        sm: "span 4", // 2-column span on small screens
        md: "span 5", // 3-column span on medium screens
        lg: "span 12", // 2-column span on large screens
      }}
      gridRow="span 2"
      backgroundColor={colors.primary[400]}
    >
      <TableContainer
        backgroundColor={colors.blueAccent[900]}
        sx={{ padding: 3 }}
      >
        <Table
          sx={{
            // minWidth: 650,
            backgroundColor: colors.blueAccent[900],
            border: "1px solid",
            // margin: 3,
          }}
          aria-label="simple table"
        >
          <TableBody>
            <TableRow
              key={1}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Type de tickets"}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Nombre de tickets"}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Montant total (DH)"}
              </TableCell>
            </TableRow>
            <TableRow
              key={2}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Ticket gratuit"}
              </TableCell>
              <TableCell>{free_tickets_count || 0}</TableCell>
              <TableCell>{0}</TableCell>
            </TableRow>
            <TableRow
              key={2}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Ticket 1 heure"}
              </TableCell>
              <TableCell>{between_2_and_3_count}</TableCell>
              <TableCell>{between_2_and_3_amount}</TableCell>
            </TableRow>
            <TableRow
              key={22}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Ticket 2 heures"}
              </TableCell>
              <TableCell>{between_4_and_5_count}</TableCell>
              <TableCell>{between_4_and_5_amount}</TableCell>
            </TableRow>
            <TableRow
              key={2}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Ticket <= 60 DH & durée > 2 h"}
              </TableCell>
              <TableCell>{between_6_and_60_count}</TableCell>
              <TableCell>{between_6_and_60_amount}</TableCell>
            </TableRow>
            <TableRow
              key={2}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"60 DH < Ticket <= 120 DH"}
              </TableCell>
              <TableCell>{between_61_and_120_count}</TableCell>
              <TableCell>{between_61_and_120_amount}</TableCell>
            </TableRow>
            <TableRow
              key={2}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"120 DH < Ticket <= 180 DH"}
              </TableCell>
              <TableCell>{between_121_and_180_count}</TableCell>
              <TableCell>{between_121_and_180_amount}</TableCell>
            </TableRow>
            <TableRow
              key={2}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Ticket > 180 DH"}
              </TableCell>
              <TableCell>{more_than_180_count}</TableCell>
              <TableCell>{more_than_180_amount}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
