import { ResponsiveLine } from "@nivo/line";
import { useTheme } from "@mui/material";
import { tokens } from "../theme";
import { mockLineData as data } from "../data/mockData";
import { useEffect, useState } from "react";
// import axios from "axios";
import { fetchingDataForChart } from "../Utils/helpers";

const LineChart = ({
  isCustomLineColors = false,
  params = {},
  isDashboard = false,
  dataP = [],
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  useEffect(() => {
  }, []);
  // const [data, setData] = useState([{
  //   id: "Rate In %",
  //   color: tokens("dark").redAccent[200],
  //   data: [],
  // }]);

  // useEffect(() => {
  //   fetchingDataForChart("report/hourlyOccupanyRate?p_id=2&date=2024-08-11", setData);
  // }, []);

  // useEffect(() => {
  //   fetchingDataForChart("report/hourlyOccupanyRate?p_id=2&date=2024-08-11", setData);
  // }, []);

  return (
    <ResponsiveLine
      // animate
      data={dataP}
      theme={{
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
        tooltip: {
          container: {
            color: colors.primary[500],
          },
        },
      }}
      colors={isDashboard ? { datum: "color" } : { scheme: "nivo" }} // added
      margin={{ top: 50, right: 110, bottom: 50, left: 60 }}
      xScale={{ type: "point" }}
      yScale={{
        type: "linear",
        min: "auto",
        max: "auto",
        // stacked: true,
        // reverse: false,
      }}
      yFormat=" >-.2f"
      curve="monotoneX"
      // pointBorderWidth={1}
      // pointSize={16}
      // pointSymbol={function noRefCheck() {}}
      // useMesh
      // width={900}
      // xFormat="time:%Y-%m-%d"
      // xScale={{
      //   format: '%Y-%m-%d',
      //   precision: 'day',
      //   type: 'time',
      //   useUTC: false
      // }}
      // yScale={{
      //   type: 'linear'
      // }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        orient: "bottom",
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : params?.bottom_title ?? "No Data", // added
        legendOffset: 36,
        legendPosition: "middle",
      }}
      axisLeft={{
        orient: "left",
        tickValues: 10, // added
        tickSize: 3,
        tickPadding: 5,
        tickRotation: 0,
        legend: isDashboard ? undefined : params?.left_title ?? "No Data", // added
        legendOffset: -40,
        legendPosition: "middle",
      }}
      enableGridX={false}
      enableGridY={false}
      pointSize={8}
      pointColor={{ theme: "background" }}
      pointBorderWidth={2}
      pointBorderColor={{ from: "serieColor" }}
      pointLabelYOffset={-12}
      useMesh={true}
      legends={[
        {
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 0,
          itemDirection: "left-to-right",
          itemWidth: 80,
          itemHeight: 20,
          itemOpacity: 0.75,
          symbolSize: 12,
          symbolShape: "circle",
          symbolBorderColor: "rgba(0, 0, 0, .5)",
          effects: [
            {
              on: "hover",
              style: {
                itemBackground: "rgba(0, 0, 0, .03)",
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
    />
  );
};

export default LineChart;
