import { Typography, Box, useTheme } from "@mui/material";
import { tokens } from "../theme";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      sx={{
        mb: {
          xs: "10px",
          sm: "30px",
        },
      }}
      flex={1}
    >
      <Typography
        variant="h2"
        color={colors.grey[100]}
        fontWeight="bold"
        sx={{
          m: "0 0 5px 0",
          textAlign: {
            xs: "center",
            sm: "left",
          },
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="h5"
        color={colors.greenAccent[400]}
        sx={{ textAlign: { xs: "center", sm: "left" } }}
      >
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;
