import { Box, Button, Typography } from "@mui/material";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import DropDownMenu from "../../components/DropDownMenu";
import { useEffect, useState } from "react";
import { dateFormater, fetchingData } from "../../Utils/helpers";
import PdfTemplate from "../../components/PdfTemplate";
// import  '@react-pdf/renderer'
// ===================================================
import { FaCarSide } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaMoneyBill1 } from "react-icons/fa6";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { mockDataContacts } from "../../data/mockData";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { IoCarSharp } from "react-icons/io5";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { MdSsidChart } from "react-icons/md";
import StatBoxExtend from "../../components/StatBoxExtend";
import GeneratePdf from "../../components/PdfTemplate";
import { BASE_URL } from "../../Constant";
import axios from "axios";
import dayjs from "dayjs";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { BillaZ } from "../../components/Pdf Templates/statisticesRep";

const TicketZ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [ticketZList, setTicketZList] = useState([]);
  const [parkings, setParkings] = useState([]);
  // const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));
  const [selectedDate, setSelectedDate] = useState(dayjs(new Date()));

  const handlePakringChanged = (Parking, setAnchorEl) => {
    setCurrentParking(Parking);
    setAnchorEl(null);
  };
  const [currentParking, setCurrentParking] = useState({
    id: 1,
    title: "Bab Jdid P1-P2",
  });

  useEffect(() => {
    (async function () {
      let datalist = [];
      let query = `${BASE_URL}/sessions/old_sessions?id=${currentParking.id}`;
      try {
        if (selectedDate != dayjs(new Date())) {
          query = `${BASE_URL}/sessions/old_sessions?id=${
            currentParking.id
          }&date=${selectedDate.format("YYYY-MM-DD")}`;
        }

        const { data } = await axios.get(query);

        datalist = data?.data.map((item, index) => {
          item.id = index;
          return item;
        });

        console.log("====================================");
        console.log(datalist);
        console.log("====================================");
        setTicketZList(datalist);
      } catch (e) {
        console.log("error fetching data", e);
      }
    })();
    // getAll Parkings =====================
    fetchingData(`global/getAllParkings`, setParkings);
  }, [currentParking, selectedDate]);

  const columns = [
    {
      field: "username",
      headerName: "Username",
    },
    {
      field: "start_time",
      headerName: "Début De Session",
      valueGetter: (value) => {
        return dayjs(value.value).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      field: "end_time",
      headerName: "Fin de la session",
      valueGetter: (value) => {
        return dayjs(value.value).format("YYYY-MM-DD HH:mm:ss");
      },
    },
    {
      field: "in_count",
      headerName: "Nombre D'entrées",
    },
    {
      field: "out_count",
      headerName: "Nombre De Sorties",
    },
    {
      field: "normal_amout",
      headerName: "Montant normal",
      valueGetter: (value) => {
        return `${value.value}DH`;
      },
    },
    {
      field: "penalty_count",
      headerName: "N. Ticket perdu",
    },
    {
      field: "penalty_amount",
      headerName: "TP Montant",
      valueGetter: (value) => {
        return `${value.value}DH`;
      },
    },
    {
      field: "manuel_count",
      headerName: "N. Ticket Manuel",
    },
    {
      field: "manuel_amount",
      headerName: "TM Montant",
      valueGetter: (value) => {
        return `${value.value}DH`;
      },
    },
    {
      field: "id",
      headerName: "Total Amount",
      renderCell: ({ row }) => {
        return (
          <span style={{ fontWeight: "bold", fontSize: 13 }}>
            {parseInt(row.penalty_amount) +
              parseInt(row.normal_amout) +
              parseInt(row.manuel_amount)}
            DH
          </span>
        );
      },
    },
    {
      headerName: "Print",
      renderCell: (row) => {
        return (
          <Button onClick={() => BillaZ(row, currentParking?.title)}>
            <Box
              m="0 auto"
              p="5px"
              display="flex"
              justifyContent="center"
              backgroundColor={colors.greenAccent[600]}
              borderRadius="4px"
              sx={{
                // backgroundColor: 'primary.main',
                "&:hover": {
                  cursor: "pointer",
                },
              }}
            >
              <PictureAsPdfIcon color="white" />
              <Typography color="white" sx={{ ml: "5px" }}>
                Print
              </Typography>
            </Box>
          </Button>
        );
      },
    },
    // {
    //   headerName: "Montant total",
    //   flex: 1.5,
    // valueGetter: (value) => {
    //   return dateFormater(value.value);
    // },
    // renderCell: (row) => {
    //   row = row.row;
    //   return (
    //     <span>
    //       {parseInt(row.pt_amount) +
    //         parseInt(row.normal_amount) +
    //         parseInt(row.mt_amount)}
    //       DH
    //     </span>
    //   );
    // },
    // },
    //   renderCell:({row: {date_time}})=>{
    //     ret
    //   }
    //   renderCell: ({ row: { access } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         backgroundColor={
    //           access === "admin"
    //             ? colors.greenAccent[600]
    //             : access === "manager"
    //             ? colors.greenAccent[700]
    //             : colors.greenAccent[700]
    //         }
    //         borderRadius="4px"
    //       >
    //         {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
    //         {access === "manager" && <SecurityOutlinedIcon />}
    //         {access === "user" && <LockOpenOutlinedIcon />}
    //         <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
    //           {access}
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          flexDirection: {
            xs: "column", // Column layout on extra-small screens
            sm: "row", // Row layout on small screens and up
          },
          mb: {
            xs: 2,
            sm: 0,
          },
        }}
      >
        <Header title="Les BillanZ" subtitle="Liste Des BillanZ" />

        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
          sx={{
            flexDirection: {
              xs: "column-reverse", // Column layout on extra-small screens
              sm: "row", // Row layout on small screens and up
            },
            mb: {
              xs: 2,
              sm: 0,
            },
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                value={selectedDate}
                onChange={(value) => setSelectedDate(value)}
                label="Select The Date"
              />
            </DemoContainer>
          </LocalizationProvider>
          <DropDownMenu
            showAllParkings={false}
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box>

        {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download PDF"
          }
        </PDFDownloadLink> */}
      </Box>

      <Box
        height="74vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={ticketZList}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default TicketZ;
