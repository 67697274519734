import { useTheme } from "@emotion/react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { tokens } from "../../theme";
import LineChart from "../../components/LineChart";
import { useEffect, useState } from "react";
import { fetchingDataForChart } from "../../Utils/helpers";
import { isEmptyArray } from "formik";

const OccupationRate = ({ hourlyOccupanyRateData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [occupationRate, setOccupationRate] = useState([
    {
      id: "Occupation Rate in %",
      color: colors.greenAccent[500],
      data: [],
    },
  ]);

  useEffect(() => {
    let occupationRateLocalData = [];
    // try {
    //   occupationRateLocalData = JSON.parse(
    //     localStorage.getItem("hourlyOccupanyRateData")
    //   );
    //   if (occupationRate) {
    //     setOccupationRate((prev) => {
    //       return [
    //         {
    //           ...prev[0],
    //           // color: "#ffffff",
    //           data: occupationRateLocalData?.data || [],
    //         },
    //       ];
    //     });
    //   }
    // } catch {}

    if (
      hourlyOccupanyRateData.length != 0 &&
      hourlyOccupanyRateData &&
      JSON.stringify(hourlyOccupanyRateData) !==
        JSON.stringify(occupationRateLocalData)
    ) {
      localStorage.setItem(
        "hourlyOccupanyRateData",
        JSON.stringify(hourlyOccupanyRateData)
      );
      setOccupationRate((prev) => {
        return [
          {
            ...prev[0],
            // color: "#ffffff",
            data: hourlyOccupanyRateData?.data || [],
          },
        ];
      });
    }

    return () => {};
  }, [hourlyOccupanyRateData]);

  return (
    <Box
      gridColumn={{
        xs: "span 2", // Full width on extra-small screens
        sm: "span 4", // 2-column span on small screens
        md: "span 6", // 3-column span on medium screens
        lg: "span 8", // 2-column span on large screens
      }}
      gridRow="span 2"
      backgroundColor={colors.primary[400]}
    >
      <Box
        mt="25px"
        p="0 30px"
        display="flex "
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
            Taux d'occupation d'aujourd'hui
          </Typography>
          {/* <Typography
          variant="h3"
          fontWeight="bold"
          color={colors.greenAccent[500]}
        >
          $59,342.32
        </Typography> */}
        </Box>
        {/* <Box>
        <IconButton>
          <DownloadOutlinedIcon
            sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
          />
        </IconButton>
      </Box> */}
      </Box>
      <Box height="250px" m="-20px 0 0 0">
        {isEmptyArray(occupationRate[0].data) ||
        occupationRate[0].data == [] ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={250}
          >
            <CircularProgress
              size={30}
              style={{ color: colors.blueAccent[100] }}
            />
          </Box>
        ) : (
          <LineChart isDashboard={true} dataP={occupationRate} />
        )}
        {/* <LineChart isDashboard={true} dataP={occupationRate} /> */}
      </Box>
    </Box>
  );
};

export default OccupationRate;
