import {
  Avatar,
  Box,
  Drawer,
  IconButton,
  List,
  ListItemText,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ColorModeContext, tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { IoMenu } from "react-icons/io5";
import { RiLiveLine } from "react-icons/ri";
import { IoIosPaper } from "react-icons/io";

import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import BarChartIcon from "@mui/icons-material/BarChart";
import { MdLiveTv } from "react-icons/md";
import { MdChecklistRtl } from "react-icons/md";
import GroupsIcon from "@mui/icons-material/Groups";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { Link } from "react-router-dom";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <Box
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        paddingTop={2}
        gap={1}
      >
        {/* <IconButton icon={icon} style={{color:"white"}}></IconButton> */}
        {icon}
        <Typography>{title}</Typography>
        <Link to={to} />
      </Box>
    </Link>
  );
};

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const [timer, setTimer] = useState(new Date());
  const { user, signOut } = useAuthenticator((context) => [context.user]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState("Dashboard");
  const open = Boolean(anchorEl);
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  useEffect(() => {
    setInterval(() => {
      setTimer(new Date());
    }, 1000);
  }, []);
  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      {/* <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase sx={{ ml: 2, flex: 1 }} placeholder="Search" />
        <IconButton type="button" sx={{ p: 1 }}>
          <SearchIcon />
        </IconButton>
      </Box> */}
      <Typography fontSize={30} fontWeight={"bold"}>
        {timer.toLocaleTimeString()}
      </Typography>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>

        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar
              style={{
                textTransform: "uppercase",
                color: colors.blueAccent[100],
              }}
            >
              {user.username[0]}
            </Avatar>
          </IconButton>
        </Tooltip>

        <IconButton
          onClick={toggleDrawer("top", true)}
          sx={{ display: { xs: "block", md: "none" } }}
        >
          <IoMenu width={100} height={100} />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          slotProps={{
            paper: {
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <MenuItem onClick={handleClose}>
            <Avatar /> Profile
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonAdd fontSize="small" />
            </ListItemIcon>
            Add another account
          </MenuItem>
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            Settings
          </MenuItem>
          <MenuItem onClick={signOut}>
            <ListItemIcon>
              <Logout fontSize="small" />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>

        <Drawer
          anchor={"top"}
          open={state["top"]}
          onClose={toggleDrawer("top", false)}
        >
          <Box
            paddingBottom={3}
            paddingTop={3}
            role="presentation"
            onClick={toggleDrawer("top", false)}
            onKeyDown={toggleDrawer("top", false)}
          >
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider sx={{ mt: 2 }} />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "5px 0 0px 60px" }}
            >
              Statistiques
            </Typography>
            <Item
              title="Journalier"
              to="/dailyReport"
              icon={<DescriptionIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Mensuel"
              to="/monthlyReport"
              icon={<BarChartIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Divider sx={{ mt: 2 }} />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "5px 0 0px 60px" }}
            >
              Abonnes
            </Typography>

            <Item
              title="Live Data"
              to="/subscribersLiveData"
              icon={<MdLiveTv />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="List Des Abonnes"
              to="/subscribersList"
              icon={<MdChecklistRtl />}
              selected={selected}
              setSelected={setSelected}
            />
            <Divider sx={{ mt: 2 }} />
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "5px 0 0px 60px" }}
            >
              Gestion Des Utilisateurs
            </Typography>
            <Item
              title="Caissiers List"
              to="/caissiers-list"
              icon={<GroupsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Live Session"
              to="/livesessions"
              icon={<RiLiveLine />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Les Bilanz"
              to="/billanz"
              icon={<IoIosPaper />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
        </Drawer>
      </Box>
    </Box>
  );
};

export default Topbar;
