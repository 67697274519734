import {
  Box,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@emotion/react";

// =====================================
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import AutoDeleteIcon from "@mui/icons-material/AutoDelete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useEffect, useState } from "react";
import { fetchingData } from "../../Utils/helpers";

const RegisteredCars = ({
  style,
  lastmonthSubscriptionRepoData,
  setOpen,
  setSubscribersDialogType,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [progress, setProgress] = useState(0);
  const [lastMonthReport, setLastMonthReport] = useState({
    new_sub: 0,
    expired: "0",
    expiring: "0",
  });
  useEffect(() => {
    // fetchingData("subscription/lastmonthreport", setLastMonthReport);

    // let timer = setInterval(() => {
    //   setProgress((oldProgress) => {
    //     if (oldProgress === 100) {
    //       return 0;
    //     }
    //     // const diff = Math.random() * 10;
    //     return oldProgress + 1;
    //   });
    // }, 100);
    let subscriberLocalData = [];
    // try {
    //   subscriberLocalData = JSON.parse(
    //     localStorage.getItem("lastmonthSubscriptionRepoData")
    //   );
    //   if (subscriberLocalData) {
    //     setLastMonthReport(subscriberLocalData);
    //   }
    // } catch {}

    setLastMonthReport({
      new_sub: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      expired: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      expiring: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
    });

    if (
      lastmonthSubscriptionRepoData.length != 0 &&
      JSON.stringify(lastmonthSubscriptionRepoData) !==
        JSON.stringify(subscriberLocalData)
    ) {
      localStorage.setItem(
        "lastmonthSubscriptionRepoData",
        JSON.stringify(lastmonthSubscriptionRepoData)
      );
      setLastMonthReport(lastmonthSubscriptionRepoData);
    }
    return () => {
      // clearInterval(timer);
    };
  }, [lastmonthSubscriptionRepoData]);

  return (
    <Box
      gridColumn={{
        xs: "span 2", // Full width on extra-small screens
        sm: "span 4", // 2-column span on small screens
        md: "span 3", // 3-column span on medium screens
        lg: "span 4", // 2-column span on large screens
      }}
      gridRow="span 2"
      backgroundColor={colors.primary[400]}
      style={{ ...style }}
    >
      <LinearProgress
        variant="determinate"
        value={progress}
        style={{ color: "#3687D8", backgroundColor: "white" }}
        // backgroundColor="red"
        color="inherit"
        maxHeight={100}
      />
      <Box style={{ padding: "30px" }}>
        <Typography variant="h5" fontWeight="600" sx={{ marginBottom: "15px" }}>
          Derniers 30 jours : Voitures enregistrées{" "}
        </Typography>

        <Box
          display="flex"
          sx={{ flexDirection: { xs: "column", sm: "row" } }}
          justifyContent={"space-between"}
          gap={2}
        >
          <Box
            backgroundColor={colors.greenAccent[700]}
            borderRadius={2}
            flex={1}
            sx={{
              cursor: "pointer",
              padding: {
                xs: "5px",
                sm: "10px 20px",
                md: "10px 30px",
              },
            }}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            onClick={() => {
              setSubscribersDialogType(1);
              setOpen(true);
            }}
          >
            <DataSaverOnIcon style={{ color: "white", fontSize: "30px" }} />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
              // gap={1}
            >
              <Typography
                fontSize={{ md: "20px", lg: "25px" }}
                fontWeight={"bold"}
              >
                {lastMonthReport.new_sub}
              </Typography>
              <Typography
                fontSize={{ md: "15px", lg: "20px" }}
                fontWeight={"bold"}
              >
                Nouveau
              </Typography>
            </Box>
          </Box>

          <Box
            flex={1}
            backgroundColor={colors.redAccent[700]}
            // marginRight={2}
            // padding="10px 0px"
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            borderRadius={2}
            sx={{
              cursor: "pointer",
              padding: {
                xs: "5px",
                sm: "10px 20px",
                md: "10px 30px",
              },
            }}
            onClick={() => {
              setSubscribersDialogType(2);
              setOpen(true);
            }}
          >
            <DeleteForeverIcon style={{ color: "white", fontSize: 40 }} />
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography
                fontSize={{ md: "20px", lg: "25px" }}
                fontWeight={"bold"}
              >
                {lastMonthReport.expired}
              </Typography>
              <Typography
                fontSize={{ md: "15px", lg: "20px" }}
                fontWeight={"bold"}
              >
                Expiré
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          flex={1}
          backgroundColor={colors.redAccent[500]}
          marginTop={2}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          borderRadius={2}
          sx={{
            cursor: "pointer",
            padding: {
              xs: "5px",
              sm: "10px 20px",
              md: "10px 30px",
            },
          }}
          onClick={() => {
            setSubscribersDialogType(3);
            setOpen(true);
          }}
        >
          <AutoDeleteIcon style={{ color: "white", fontSize: 40 }} />
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <Typography
              fontSize={{ md: "20px", lg: "25px" }}
              fontWeight={"bold"}
            >
              {lastMonthReport.expiring}
            </Typography>
            <Typography
              fontSize={{ md: "15px", lg: "20px" }}
              fontWeight={"bold"}
            >
              Proche de l'expiration
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RegisteredCars;
