import { useTheme } from "@emotion/react";
import { Box, Typography } from "@mui/material";
import { tokens } from "../../theme";
import LineChart from "../../components/LineChart";
import { useEffect, useState } from "react";
import { fetchData } from "../../Utils/helpers";
import useSocket from "../../hooks/useSocket";
import BarChart from "../../components/BarChart";
import { mockBarDataPricing } from "../../data/mockData";
const SubscribersBarChart = ({ subscribersChartData }) => {
  // const socket = useSocket("http://localhost:5000");

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState();

  // useEffect(() => {
  //   try {
  //     let data = JSON.parse(localStorage.getItem("dashboard/inOutCarFlow"));
  //     if (data) {
  //       setInOutCarFlow((prev) => {
  //         if (data == undefined) return prev;
  //         return [
  //           {
  //             ...prev[0],
  //             data: Array.isArray(data?.out_data)
  //               ? data.out_data
  //               : [{ x: "Flux entrant", y: 0 }],
  //           },
  //           {
  //             ...prev[1],
  //             data: Array.isArray(data?.in_data)
  //               ? data.in_data
  //               : [{ x: "Flux sortant", y: 0 }],
  //           },
  //         ];
  //       });
  //     }
  //   } catch (e) {}

  //   if (
  //     inOutData.length != 0 &&
  //     inOutData &&
  //     JSON.stringify(inOutData) !== JSON.stringify(data)
  //   ) {
  //     localStorage.setItem("dashboard/inOutCarFlow", JSON.stringify(inOutData));
  //     console.log("Updated localStorage: ", inOutData);
  //     setInOutCarFlow((prev) => {
  //       if (inOutData == undefined) return prev;
  //       return [
  //         {
  //           ...prev[0],
  //           data: Array.isArray(inOutData?.out_data)
  //             ? inOutData.out_data
  //             : [{ x: "Flux entrant", y: 0 }],
  //         },
  //         {
  //           ...prev[1],
  //           data: Array.isArray(inOutData?.in_data)
  //             ? inOutData.in_data
  //             : [{ x: "Flux sortant", y: 0 }],
  //         },
  //       ];
  //     });
  //   }

  //   return () => {};
  // }, [inOutData]);
  return (
    <Box
      gridColumn={{
        xs: "span 2", // Full width on extra-small screens
        sm: "span 4", // 2-column span on small screens
        md: "span 5", // 3-column span on medium screens
        lg: "span 7", // 2-column span on large screens
      }}
      gridRow="span 2"
      backgroundColor={colors.primary[400]}
    >
      <Box
        mt="25px"
        p="0 30px"
        display="flex "
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
            Flux de voitures entrée-sortie
          </Typography>
        </Box>
      </Box>
      <Box height="400px" m="-20px 0 0 0">
        <BarChart
          isDashboard={true}
          datap={subscribersChartData}
          titles={["period"]}
        />
      </Box>
    </Box>
  );
};

export default SubscribersBarChart;
