import { Box, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import DropDownMenu from "../../components/DropDownMenu";
import { useEffect, useState } from "react";
import { fetchingData } from "../../Utils/helpers";
import dayjs from "dayjs";

const CaissiersList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [dailyReport, setDailyReport] = useState([]);
  const [parkings, setParkings] = useState([]);

  const handlePakringChanged = (Parking, setAnchorEl) => {
    setCurrentParking(Parking);
    setAnchorEl(null);
  };
  const [currentParking, setCurrentParking] = useState({
    id: 0,
    title: "Tous les parkings",
  });

  const [caissierList, setCaissierList] = useState([]);

  useEffect(() => {
    fetchingData(
      `users/users_sessions?id=${currentParking.id}`,
      setCaissierList
    );
    // getAll Parkings =====================
  }, [currentParking]);

  useEffect(() => {
    fetchingData(`global/getAllParkings`, setParkings);
  }, []);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "username",
      headerName: "Username",
      cellClassName: "name-column--cell",
    },
    {
      field: "last_session_opned",
      headerName: "Dernière session (l'Ouverture)",
      type: "number",
      valueGetter: (value) => {
        return dayjs(value.value).format("YYYY-MM-DD  HH:mm:ss");
      },
      headerAlign: "left",
      align: "left",
    },
    {
      field: "last_session_closed",
      headerName: "Dernière session (Fermeture)",
      type: "number",
      valueGetter: (value) => {
        return dayjs(value.value).format("YYYY-MM-DD HH:mm:ss");
      },
      headerAlign: "left",
      align: "left",
    },
    {
      field: "number_of_sessions",
      headerName: "Nombre de Séances",
    },
    {
      field: "type",
      headerName: "Access Level",
      renderCell: ({ row: { type } }) => {
        return (
          <Box
            width={{
              xs: "100px",
              md: "80%",
            }}
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              // type.match(/^adm/i)
              /^adm|^sub/i.test(type)
                ? colors.greenAccent[600]
                : /^sup/i.test(type)
                ? colors.greenAccent[700]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {/^adm|^sub/i.test(type) && <AdminPanelSettingsOutlinedIcon />}
            {/^sup/i.test(type) && <SecurityOutlinedIcon />}
            {/^cai/i.test(type) && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {type}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          flexDirection: {
            xs: "column", // Column layout on extra-small screens
            sm: "row", // Row layout on small screens and up
          },
          mb: {
            xs: 2,
            md: 0,
          },
        }}
      >
        <Header
          title="Caissiers List"
          subtitle="Ce tableau est la liste des Caissières du parking"
        />

        <Box>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box>

        {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download PDF"
          }
        </PDFDownloadLink> */}
      </Box>
      <Box
        height="74vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid checkboxSelection rows={caissierList} columns={columns} />
      </Box>
    </Box>
  );
};

export default CaissiersList;
