import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159),
  createData("Ice cream sandwich", 237),
  createData("Eclair", 262),
  createData("Cupcake", 305),
  createData("Gingerbread", 356),
];

export default function DailyTable({
  currentDayReport,
  currentParking,
  restDataOfTheTable,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      gridColumn={{
        xs: "span 2", // Full width on extra-small screens
        sm: "span 4", // 2-column span on small screens
        md: "span 3", // 3-column span on medium screens
        lg: "span 4", // 2-column span on large screens
      }}
      gridRow="span 2"
      backgroundColor={colors.primary[400]}
    >
      <TableContainer
        backgroundColor={colors.blueAccent[900]}
        sx={{ padding: 3 }}
      >
        <Table
          sx={{
            // minWidth: 650,
            backgroundColor: colors.blueAccent[900],
            border: "1px solid",
            // margin: 3,
          }}
          aria-label="simple table"
        >
          <TableBody>
            <TableRow
              key={1}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Nomber De Ticket Normal"}
              </TableCell>
              <TableCell>{currentDayReport?.in_count || 0}</TableCell>
            </TableRow>
            <TableRow
              key={2}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Nombre De Ticket Perdu"}
              </TableCell>
              <TableCell>{currentDayReport?.pt_count || 0}</TableCell>
            </TableRow>
            <TableRow
              key={3}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Nomber De Ticket Illisible"}
              </TableCell>
              <TableCell>{currentDayReport?.mt_count || 0}</TableCell>
            </TableRow>
            <TableRow
              key={4}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                component="th"
                scope="row"
                sx={{ fontSize: 14, fontWeight: "bold" }}
              >
                {"Tickets < 15min"}
              </TableCell>
              <TableCell>{restDataOfTheTable.free_tickets}</TableCell>
            </TableRow>
            <TableRow
              key={5}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                sx={{ fontSize: 14, fontWeight: "bold" }}
                component="th"
                scope="row"
              >
                {"Ratio De Rotation (NE/CP)"}
              </TableCell>
              <TableCell>
                {(
                  ((isNaN(parseInt(currentDayReport.in_count))
                    ? 0
                    : currentDayReport.in_count) /
                    (currentParking?.size || 1)) *
                  100
                ).toFixed(2)}
                %
              </TableCell>
            </TableRow>
            <TableRow
              key={6}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                sx={{ fontSize: 14, fontWeight: "bold" }}
                component="th"
                scope="row"
              >
                {"Taux D'occupation Moyen Du Parking"}
              </TableCell>
              <TableCell>
                {(
                  (isNaN(restDataOfTheTable.avrage_occupation_rate)
                    ? 0
                    : restDataOfTheTable.avrage_occupation_rate) / 24
                ).toFixed(2)}
                %
              </TableCell>
            </TableRow>
            <TableRow
              key={7}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                sx={{ fontSize: 14, fontWeight: "bold" }}
                component="th"
                scope="row"
              >
                {"Duree Moyenne De Stationnement"}
              </TableCell>
              <TableCell>{restDataOfTheTable.avrage_time}</TableCell>
            </TableRow>
            <TableRow
              key={8}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                sx={{ fontSize: 14, fontWeight: "bold" }}
                component="th"
                scope="row"
              >
                {"Ticket Moyenne"}
              </TableCell>
              <TableCell>{`${Math.round(
                (parseInt(currentDayReport?.out_amount) +
                  parseInt(currentDayReport?.pt_amount) +
                  parseInt(currentDayReport?.mt_amount)) /
                  (parseInt(currentDayReport?.out_count) +
                    parseInt(currentDayReport?.pt_count) +
                    parseInt(currentDayReport?.mt_count)) || 0
              )}HD`}</TableCell>
            </TableRow>
            <TableRow
              key={9}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell
                sx={{ fontSize: 14, fontWeight: "bold" }}
                component="th"
                scope="row"
              >
                {"Taux De Remplissage"}
              </TableCell>
              <TableCell>0</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
