import { useTheme } from "@emotion/react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { tokens } from "../../theme";
import LineChart from "../../components/LineChart";
import { useEffect, useState } from "react";
import { fetchData } from "../../Utils/helpers";
import useSocket from "../../hooks/useSocket";
import { isEmptyArray } from "formik";

const RevenueChart = ({ RevenueData }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [revenueChart, setRevenueChart] = useState([
    {
      id: "Revenue",
      color: colors.greenAccent[500],
      data: [],
    },
  ]);
  const [data, setData] = useState();

  useEffect(() => {
    try {
      let data = JSON.parse(localStorage.getItem("DailyReport/RevenueData"));
      if (data) {
        setRevenueChart((prev) => {
          if (data == undefined) return prev;
          return [
            {
              ...prev[0],
              data: Array.isArray(data) ? data : [{ x: "Revenue", y: 0 }],
            },
          ];
        });
      }
    } catch (e) {}

    if (
      RevenueData.length != 0 &&
      RevenueData &&
      JSON.stringify(RevenueData) !== JSON.stringify(data)
    ) {
      localStorage.setItem(
        "DailyReport/RevenueData",
        JSON.stringify(RevenueData)
      );
      setRevenueChart((prev) => {
        if (RevenueData == undefined) return prev;
        return [
          {
            ...prev[0],
            data: Array.isArray(RevenueData)
              ? RevenueData
              : [{ x: "Reveneue", y: 0 }],
          },
        ];
      });
    } else {
      setRevenueChart([
        {
          id: "Revenue",
          color: colors.greenAccent[500],
          data: [],
        },
      ]);
    }

    return () => {};
  }, [RevenueData]);
  return (
    <Box
      gridColumn={{
        xs: "span 2", // Full width on extra-small screens
        sm: "span 4", // 2-column span on small screens
        md: "span 6", // 3-column span on medium screens
        lg: "span 8", // 2-column span on large screens
      }}
      gridRow="span 2"
      backgroundColor={colors.primary[400]}
    >
      <Box
        mt="25px"
        p="0 30px"
        display="flex "
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h5" fontWeight="600" color={colors.grey[100]}>
            The Revenue Of This Day
          </Typography>
        </Box>
      </Box>
      <Box height="500px" m="-20px 0 0 0">
        {isEmptyArray(revenueChart[0].data) || revenueChart[0].data == [] ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={500}
          >
            <CircularProgress
              size={30}
              style={{ color: colors.blueAccent[100] }}
            />
          </Box>
        ) : (
          <LineChart isDashboard={true} dataP={revenueChart} />
        )}
      </Box>
    </Box>
  );
};

export default RevenueChart;
