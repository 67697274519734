import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import {
  Box,
  IconButton,
  selectClasses,
  Typography,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../../theme";
import AccordionComponent from "../../components/AccordionComponent";
import { useNavigate } from "react-router-dom";
// ========================================
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import TimelineOutlinedIcon from "@mui/icons-material/TimelineOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import { RiLiveLine } from "react-icons/ri";
import { IoIosPaper } from "react-icons/io";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { RiVipCrown2Line } from "react-icons/ri";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import GroupsIcon from "@mui/icons-material/Groups";
import ArticleIcon from "@mui/icons-material/Article";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import DescriptionIcon from "@mui/icons-material/Description";
import BarChartIcon from "@mui/icons-material/BarChart";
import { MdLiveTv } from "react-icons/md";
import { MdChecklistRtl } from "react-icons/md";
// import GroupsIcon from "@mui/icons-material/Groups";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
      }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const AccordionItem = ({ style, title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{
        color: colors.grey[100],
        ...style,
      }}
      onClick={() => setSelected(title)}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const AccordionIconItem = ({ to, icon, selected, setSelected, title }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      active={selected === title}
      // style={{
      //   color: colors.grey[100],
      // }}
      sx={{
        mt: 2,
        marginLeft: "11px",
      }}
      onClick={() => setSelected(title)}
    >
      {/* <Typography>{title}</Typography> */}
      {icon}
      <Link to={to} />
    </Box>
  );
};
const Sidebar = ({ isSideBarCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");
  const navigate = useNavigate();

  useEffect(() => {
    isSideBarCollapsed(isCollapsed);
  }, [isCollapsed]);

  const checkScreenWidth = () => {
    if (window.innerWidth < 900) {
      // setIsSmallScreen(true);
      setIsCollapsed(true);
      // isSideBarCollapsed(true);
    }
  };
  useEffect(() => {
    // Check screen width on initial render
    checkScreenWidth();

    // Add event listener for window resize
    window.addEventListener("resize", checkScreenWidth);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScreenWidth);
    };
  }, []);
  const redirectTo = (endPoint) => {
    return navigate(endPoint);
  };
  return (
    <Box
      // position={"fixed"}
      // boxSizing={"border-box"}

      // width={"200px"}
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
        display: {
          xs: "none",
          md: "block",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed} style={{ position: "fixed" }}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 10px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" color={colors.grey[100]}>
                  ADMINIS
                </Typography>
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Box
                  width="100px"
                  height="100px"
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  backgroundColor="white"
                >
                  <img
                    alt="profile-user"
                    width={65}
                    // height={90}
                    src={`../../assets/user.png`}
                  />
                </Box>
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h4"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 5px 0" }}
                >
                  CGPark Dashboard
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  Supper Admin
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            <Item
              title="Dashboard"
              to="/"
              icon={<HomeOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />

            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Statistiques
            </Typography>
            <Item
              title="Journalier"
              to="/dailyReport"
              icon={<DescriptionIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Mensuel"
              to="/monthlyReport"
              icon={<BarChartIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {/* <Item
              title="Taux D'occupation"
              to="/global-occupationRate"
              icon={<ShowChartIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              {isCollapsed ? "Abonnes" : "Flux"}
            </Typography>
            {/* <Item
              title="Subscribers"
              to="/invoices"
              icon={<RiVipCrown2Line />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {isCollapsed && (
              <>
                <Item
                  title="Live Data"
                  to="/subscribersLiveData"
                  icon={<MdLiveTv />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Liste Des Abonnés"
                  to="/subscribersList"
                  icon={<MdChecklistRtl />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}

            {!isCollapsed && (
              <AccordionComponent
                title="Abonnés"
                icon={<RiVipCrown2Line />}
                isCollapsed={isCollapsed}
              >
                <AccordionItem
                  title={"Live Data"}
                  to={"/subscribersLiveData"}
                  selected={selected}
                  setSelected={setSelected}
                />
                <AccordionItem
                  style={{ marginTop: "-10px" }}
                  title={"Liste Des Abonnés"}
                  to={"/subscribersList"}
                  selected={selected}
                  setSelected={setSelected}
                />
                {/* <Box onClick={() => redirectTo("/subscribersLiveData")}>
                Live Data
              </Box> */}
                {/* <Box>Entrée</Box>
              <Box>Sortie</Box>
              <Box>Taux D'occupation</Box> */}
              </AccordionComponent>
            )}

            {/* <AccordionComponent title="Visiteurs" icon={<DirectionsCarIcon />}>
              <Box>Entrée</Box>
              <Box>Sortie</Box>
            </AccordionComponent> */}
            <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Gestion Des Utilisateurs
            </Typography>
            <Item
              title="Caissiers List"
              // to="/bar"
              to="/caissiers-list"
              icon={<GroupsIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            {isCollapsed && (
              <>
                <Item
                  title="Live Session"
                  // to="/bar"
                  to="/livesessions"
                  icon={<RiLiveLine />}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Les Bilanz"
                  // to="/bar"
                  to="/billanz"
                  icon={<IoIosPaper />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </>
            )}
            {!isCollapsed && (
              <AccordionComponent
                title="Gestion Des Sessions"
                icon={<ArticleIcon />}
              >
                <AccordionItem
                  title={"Live Session"}
                  to={"/livesessions"}
                  selected={selected}
                  setSelected={setSelected}
                />
                <AccordionItem
                  style={{ marginTop: "-10px" }}
                  title={"Les Bilanz"}
                  to={"/billanz"}
                  selected={selected}
                  setSelected={setSelected}
                />
              </AccordionComponent>
            )}
            {/* <Typography
              variant="h6"
              color={colors.grey[300]}
              sx={{ m: "15px 0 5px 20px" }}
            >
              Settings
            </Typography>
            <Item
              title="Send Report "
              to="/sendmail"
              icon={<MailOutlineIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
            {/* <Item
              title="Line Chart"
              to="/line"
              icon={<TimelineOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="Geography Chart"
              to="/geography"
              icon={<MapOutlinedIcon />}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
