import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Menu,
  Typography,
  useTheme,
} from "@mui/material";
import { tokens } from "../../theme";
import { FaCarSide } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaMoneyBill1 } from "react-icons/fa6";
import { AllParkings, BASE_URL } from "../../Constant";
import Header from "../../components/Header";
import LineChart from "../../components/LineChart";
import GeographyChart from "../../components/GeographyChart";
import BarChart from "../../components/BarChart";
import StatBox from "../../components/StatBox";
import ProgressCircle from "../../components/ProgressCircle";
import { useEffect, useState } from "react";
import axios from "axios";
import DropDownMenu from "../../components/DropDownMenu";
import { dateFormater, fetchingData } from "../../Utils/helpers";
import RegisteredCars from "./RegisteredCars";
import InOutChart from "./InOutChart";
import { FaCarRear } from "react-icons/fa6";
import { MdOutlineCreditCard } from "react-icons/md";
import { AiFillCreditCard } from "react-icons/ai";

import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import OccupationRate from "./OccupationRate";
import useSocket from "../../hooks/useSocket";
import { IoCarSharp } from "react-icons/io5";
import SubscriberListDialog from "./SubscriberListDialog";
import StatBoxExtend from "../../components/StatBoxExtend";

const calculate_percentage = (cur_value, prev_value) => {
  if (prev_value == 0) return cur_value % 1;
  if (isNaN(cur_value)) cur_value = 0;
  if (isNaN(prev_value)) prev_value = 0;
  return Math.abs(cur_value / prev_value - 1);
};
const calculate_percentage_compared_to_yesterday = (cur_value, prev_value) => {
  // console.log(typeof cur_value , typeof prev_value);
  if (isNaN(cur_value)) cur_value = 0;
  if (isNaN(prev_value)) prev_value = 0;

  let value = 0;
  if (prev_value != 0) {
    value = (cur_value * 100) / prev_value - 100;
  }

  // console.log(value);
  if (value >= 0) return `+${value.toFixed(2)}%`;
  return `${value.toFixed(2)}%`;
};

const Dashboard = ({ socket }) => {
  // const socket = useSocket("http://98.81.55.222:5001");
  // const socket = useSocket("http://localhost:5000");
  const [open, setOpen] = useState(false);
  const [subscribersDialogType, setSubscribersDialogType] = useState(0);
  const [inOutChartData, setInOutChartData] = useState([]);
  const [hourlyOccupanyRateData, setHourlyOccupanyRateData] = useState([]);
  const [lastmonthSubscriptionRepoData, setLastmonthSubscriptionRepoData] =
    useState([]);
  const [currentParking, setCurrentParking] = useState({
    id: 0,
    title: "Tous les parkings",
  });

  const [currentRevenueParking, setCurrentRevenueParking] = useState({
    id: 0,
    title: "Tous les parkings",
  });

  const [recentRevenu, setRecentRevenu] = useState([]);

  const [parkings, setParkings] = useState(AllParkings);

  const [statistics, setStatistics] = useState({
    subscribers_entry: 0,
    visitor_entry: 0,
    subscribers_exit: 0,
    visitors_exit: 0,
    today_amount: 0,
    yesterday_amount: 0,
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [change, setChange] = useState(1);

  const handlePakringChanged = (Parking, setAnchorEl) => {
    console.log("current parking", Parking);
    setCurrentParking(Parking);
    // fetchingData(
    //   `statistics/global?id=${Parking.id}&date=2024-08-10`,
    //   setStatistics
    // );

    setAnchorEl(null);
    // if (socket) {
    //   socket.emit("dashboard/get-params", Parking);
    // }
  };

  const handleParkingRevenueChanged = (parking, setAnchorEl) => {
    setCurrentRevenueParking(parking);
    // console.log(`statistics/RevenuePerParking?${parking.id}=1&limit=10`);

    fetchingData(
      `statistics/RevenuePerParking?id=${parking.id}&limit=10`,
      setRecentRevenu
    );
    setAnchorEl(null);
  };
  useEffect(() => {
    fetchingData(`statistics/RevenuePerParking?id=0&limit=10`, setRecentRevenu);
  }, []);
  useEffect(() => {
    setStatistics({
      subscribers_entry: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      visitor_entry: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      subscribers_exit: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      visitors_exit: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      today_amount: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
      yesterday_amount: (
        <CircularProgress size={30} style={{ color: colors.blueAccent[100] }} />
      ),
    });

    if (socket) {
      socket.emit("dashboard/get-params", currentParking);
      console.log("====================================");
      console.log("data....: ", currentParking);
      console.log("====================================");
      // socket.emit("setSession");
      // socket.emit("dashboard/opened", { data: "hello", backendIntervalId });
      socket.on("dashboard/get-dashboard-data", ({ data }) => {
        console.log(data);

        localStorage.setItem(
          "dashboard/get-dashboard-data",
          JSON.stringify(data)
        );
        console.log("dashboard", data.data);
        setStatistics((prev) => {
          return {
            subscribers_entry: data.data[0]?.["subscribers_entry"] || 0,
            visitor_entry: data.data[0]?.["visitor_entry"] || 0,
            subscribers_exit: data.data[0]?.["subscribers_exit"] || 0,
            visitors_exit: data.data[0]?.["visitors_exit"] || 0,
            today_amount: `${data.data[0]?.["today_amount"] || 0}DH`,
            yesterday_amount: `${data.data[0]?.["yesterday_amount"]}DH`,
          };
        });
      });

      socket.on("dashboard/inOutCarFlow", ({ data }) => {
        // console.log('====================================');
        // console.log("dashboard/inOutCarFlow",data);
        // console.log("====================================");
        setInOutChartData(data);
        // console.log("Received data from socket:", data);
        // localStorage.setItem("dashboard/inOutCarFlow", JSON.stringify(data));
        // setInOutCarFlow((prev) => {
        //   if (data == undefined) return prev;
        //   return [
        //     {
        //       ...prev[0],
        //       data: Array.isArray(data?.out_data)
        //         ? data.out_data
        //         : [{ x: "Flux entrant", y: 0 }],
        //     },
        //     {
        //       ...prev[1],
        //       data: Array.isArray(data?.in_data)
        //         ? data.in_data
        //         : [{ x: "Flux sortant", y: 0 }],
        //     },
        //   ];
        // });
      });

      socket.on("dashboard/hourlyOccupanyRate", ({ data }) => {
        console.log("==========/==========================");
        console.log("data ocuupation rate: ", data);
        console.log("====================================");
        setHourlyOccupanyRateData(data);
      });

      socket.on("dashboard/lastmonthRepo", ({ data }) => {
        // console.log(data);
        setLastmonthSubscriptionRepoData(data?.data);
      });
    }
    // dashboard/get-dashboard-data
    // fetchingData(`global/getAllParkings`, setParkings);

    return () => {
      if (socket) {
        socket.off("dashboard/get-dashboard-data");
      }
    };
    //  ==========================================================
    // statistics ==============
    // fetchingData(`statistics/global?date=2024-08-10`, setStatistics);

    // // getAll Parkings =====================

    // // RevenuePerParking ==================
  }, [socket, currentParking]);

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          flexDirection: {
            xs: "column", // Column layout on extra-small screens
            sm: "row", // Row layout on small screens and up
          },
          mb: {
            xs: 2,
            sm: 0,
          },
        }}
      >
        <Header title="Dashboard" subtitle="Welcome to your dashboard" />

        <Box>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box>
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns={{
          xs: "repeat(2, 1fr)", // 2 columns on extra-small screens
          sm: "repeat(4, 1fr)", // 4 columns on small screens
          md: "repeat(9, 1fr)", // 8 columns on medium screens
          lg: "repeat(12, 1fr)", // 12 columns on large screens
        }}
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn={{
            xs: "span 2", // Full width on extra-small screens
            sm: "span 2", // 2-column span on small screens
            md: "span 3", // 3-column span on medium screens
            lg: "span 2", // 2-column span on large screens
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={statistics.visitor_entry}
            subtitle="Nombre D'entrées Visiteur"
            icon={
              <FaCarSide
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={{
            xs: "span 2", // Full width on extra-small screens
            sm: "span 2", // 2-column span on small screens
            md: "span 3", // 3-column span on medium screens
            lg: "span 2", // 2-column span on large screens
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={statistics.subscribers_entry}
            subtitle="Nombre D'entrées Abonnes"
            icon={
              <MdOutlineCreditCard
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={{
            xs: "span 2", // Full width on extra-small screens
            sm: "span 2", // 2-column span on small screens
            md: "span 3", // 3-column span on medium screens
            lg: "span 2", // 2-column span on large screens
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={statistics.visitors_exit}
            subtitle="Nombre De Sortie Visiteurs"
            icon={
              <IoCarSharp
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={{
            xs: "span 2", // Full width on extra-small screens
            sm: "span 2", // 2-column span on small screens
            md: "span 3", // 3-column span on medium screens
            lg: "span 2", // 2-column span on large screens
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={statistics.subscribers_exit}
            subtitle="Nombre De Sorties Abonnes"
            icon={
              <AiFillCreditCard
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={{
            xs: "span 2", // Full width on extra-small screens
            sm: "span 2", // 2-column span on small screens
            md: "span 3", // 3-column span on medium screens
            lg: "span 2", // 2-column span on large screens
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={statistics.today_amount}
            subtitle="Montant actuel"
            icon={
              <FaMoneyBillTrendUp
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn={{
            xs: "span 2", // Full width on extra-small screens
            sm: "span 2", // 2-column span on small screens
            md: "span 3", // 3-column span on medium screens
            lg: "span 2", // 2-column span on large screens
          }}
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBoxExtend
            title={statistics.yesterday_amount}
            subtitle="Montant d'hier"
            icon={
              <FaMoneyBill1
                style={{ color: colors.greenAccent[600], fontSize: "30px" }}
              />
            }
          />
        </Box>

        {/* ROW 2 */}
        <InOutChart inOutData={inOutChartData} />
        <Box
          gridColumn={{
            xs: "span 2", // Full width on extra-small screens
            sm: "span 4", // 2-column span on small screens
            md: "span 3", // 3-column span on medium screens
            lg: "span 4", // 2-column span on large screens
          }}
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            borderBottom={`4px solid ${colors.primary[500]}`}
            colors={colors.grey[100]}
            p="15px"
          >
            <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
              The Privious 10 Days
            </Typography>
            <DropDownMenu
              parkings={parkings}
              selectedItem={currentRevenueParking}
              handleChanged={handleParkingRevenueChanged}
            />
          </Box>
          {recentRevenu.map((revenue, i) => (
            <Box
              key={i}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              borderBottom={`4px solid ${colors.primary[500]}`}
              p="15px"
            >
              <Box>
                <Typography
                  color={colors.greenAccent[500]}
                  variant="h5"
                  fontWeight="600"
                >
                  {dateFormater(revenue.charge_time)}
                </Typography>
                <Typography fontSize={10} color={colors.grey[100]}>
                  {currentRevenueParking.title}
                </Typography>
              </Box>
              <Box color={colors.grey[100]}>{revenue.exit_count}</Box>
              <Box
                backgroundColor={colors.greenAccent[500]}
                p="5px 10px"
                borderRadius="4px"
              >
                {revenue.revenue}DH
              </Box>
            </Box>
          ))}
        </Box>

        {/* ROW 3 */}
        {/* <InOutChart /> */}
        <OccupationRate hourlyOccupanyRateData={hourlyOccupanyRateData} />
        <RegisteredCars
          lastmonthSubscriptionRepoData={lastmonthSubscriptionRepoData}
          setOpen={setOpen}
          setSubscribersDialogType={setSubscribersDialogType}
        />
      </Box>

      {/* <SubscriberListDialog
        open={open}
        handleClickOpen={setOpen}
        handleClose={setOpen}
        subscribersDialogType={subscribersDialogType}
      /> */}
    </Box>
  );
};

export default Dashboard;
