import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import { Box } from "@mui/material";

import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";

export default function AccordionComponent({
  children,
  title,
  icon,
  isCollapsed,
}) {
  return (
    <Accordion
      sx={{
        "&:before": {
          height: 0,
        },
        "&": {
          padding: "0px 10px !important",
          //   height: 60,
          marginTop: 0,
          marginBottom: 0,
        },
      }}
      style={{
        backgroundColor: "transparent",
        backgroundImage:
          "linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0))",
        //   marginTop: "40px",
        boxShadow:
          "0px 0px 0px 0px rgba(0, 0, 0, 0), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12)",
      }}
    >
      <AccordionSummary
        aria-controls="panel2-content"
        id="panel2-header"
        className="ch-ac"
      >
        <Box
          display="flex"
          alignContent="center"
          justifyContent="space-between"
          gap={2}
        >
          {icon}
          {!isCollapsed ? title : ""}
        </Box>
      </AccordionSummary>
      <AccordionDetails
        style={{
          display: isCollapsed ? "block" : "flex",
          flexDirection: "column",
          gap: "15px",
          marginTop: "-10px",
          cursor: "pointer",
          marginLeft: isCollapsed ? "-10px" : "10px",
          paddingBottom: "0px",
          paddingTop: 0,
          zIndex: 100,
        }}
        sx={{
          "& div": {
            transition: "all 0.3s ease",
          },
          "&  div:hover": {
            transform: "translateX(10px)",
            // backgroundColor: "red",
            // borderBottom: "1px solid black",
          },
        }}
      >
        {children}
        {/* <Box style={{ marginBottom: "10px" }}>Entree</Box>
        <Box style={{ marginBottom: "10px" }}>Exit</Box>
        <Box style={{ marginBottom: "0px" }}>Exit</Box> */}
      </AccordionDetails>
    </Accordion>
  );
}
