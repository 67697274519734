import { Box, Typography } from "@mui/material";
import Header from "../../components/Header";
import StatBox from "../../components/StatBox";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import DropDownMenu from "../../components/DropDownMenu";
import { useEffect, useState } from "react";
import { dateFormater, fetchingData } from "../../Utils/helpers";
import PdfTemplate from "../../components/PdfTemplate";
// import  '@react-pdf/renderer'
// ===================================================
import { FaCarSide } from "react-icons/fa";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { FaMoneyBill1 } from "react-icons/fa6";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { mockDataContacts } from "../../data/mockData";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { PDFDownloadLink } from "@react-pdf/renderer";
import dayjs from "dayjs";

const SubscribersList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [dailyReport, setDailyReport] = useState([]);
  const [parkings, setParkings] = useState([]);

  const handlePakringChanged = (Parking, setAnchorEl) => {
    setCurrentParking(Parking);
    setAnchorEl(null);
  };
  const [currentParking, setCurrentParking] = useState({
    id: 1,
    title: "Bab Jdid P1-P2",
  });

  useEffect(() => {
    fetchingData(
      `subscription/subscribersList?id=${currentParking.id}`,
      setDailyReport
    );
    // getAll Parkings =====================
  }, [currentParking]);
  useEffect(() => {
    fetchingData(`global/getAllParkings`, setParkings);
  }, []);

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "fullname", headerName: "FullName" },
    {
      field: "create_time",
      headerName: "Register Time",
      valueGetter: (value) => {
        return dayjs(value.value).format("YYYY-MM-DD");
      },
    },
    {
      field: "is_from",
      headerName: "Type",
      valueGetter: (value) => {
        if (value.value == "1") return "24h";
        else if (value.value == "2") return "8h";
        else if (value.value == "3") return "10h";
        else return "24h";
      },
    },
    {
      field: "car_number",
      headerName: "Card Number",
      // flex: 1,
      //   cellClassName: "name-column--cell",
    },
    {
      field: "mobile_phone",
      headerName: "mobile_phone",
    },
    {
      field: "email",
      headerName: "email",
    },
    {
      field: "strat_time",
      headerName: "strat_time",
      valueGetter: (value) => {
        return dayjs(value.value).format("YYYY-MM-DD");
      },
    },
    {
      field: "end_time",
      headerName: "end_time",
      valueGetter: (value) => {
        return dayjs(value.value).format("YYYY-MM-DD");
      },
    },
    {
      field: "debt",
      headerName: "Debt",
      valueGetter: (value) => {
        return `${value.value} DH`;
      },
    },
    //   renderCell:({row: {date_time}})=>{
    //     ret
    //   }
    //   renderCell: ({ row: { access } }) => {
    //     return (
    //       <Box
    //         width="60%"
    //         m="0 auto"
    //         p="5px"
    //         display="flex"
    //         justifyContent="center"
    //         backgroundColor={
    //           access === "admin"
    //             ? colors.greenAccent[600]
    //             : access === "manager"
    //             ? colors.greenAccent[700]
    //             : colors.greenAccent[700]
    //         }
    //         borderRadius="4px"
    //       >
    //         {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
    //         {access === "manager" && <SecurityOutlinedIcon />}
    //         {access === "user" && <LockOpenOutlinedIcon />}
    //         <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
    //           {access}
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          flexDirection: {
            xs: "column", // Column layout on extra-small screens
            sm: "row", // Row layout on small screens and up
          },
          mb: {
            xs: 2,
            md: 0,
          },
        }}
      >
        <Header title="Abonnés" subtitle="Voici la liste des Acbonnés" />

        <Box>
          <DropDownMenu
            parkings={parkings}
            selectedItem={currentParking}
            handleChanged={handlePakringChanged}
          />
        </Box>

        {/* <PDFDownloadLink document={<PdfTemplate />} fileName="example.pdf">
          {({ blob, url, loading, error }) =>
            loading ? "Loading document..." : "Download PDF"
          }
        </PDFDownloadLink> */}
      </Box>

      <Box
        m="-10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={dailyReport}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default SubscribersList;
